<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Class"
              invalid-feedback="Class is required."
              ref="class"
            >
              <v-select
                ref="class"
                multiple
                :closeOnSelect="false"
                v-model="selectedClasses"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                label="name"
                placeholder="Select classes"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="save()"
              block
            >
              <!-- <b-spinner v-if="request" small type="grow" /> -->
              <span> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-row align-h="center" v-if="dataLoading" class="mt-2">
      <b-spinner
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <b-row align-h="center" v-else>
      <b-col xl="5" lg="6" md="8">
        <b-card
          class="pr-1 pl-50"
          v-for="(pcard, cardIndex) in myObj"
          :key="cardIndex"
        >
          <!-- <div class="mt-1 d-flex align-items-center justify-content-between">
        </div> -->
          <b-row>
            <b-col md="6" class="pr-0">
              <h3>Grading Policy</h3>
            </b-col>
            <b-col md="6" class="pr-0 text-right">
              <b-button
                v-if="pcard.cIDs.length == 0"
                class="btn-icon"
                variant="primary"
                @click="AddOpen(cardIndex)"
              >
                <feather-icon icon="PlusIcon" />
                Classes
              </b-button>
              <div v-else class="text-right">
                <b-badge
                  variant="light-primary"
                  v-for="cls in pcard.cIDs"
                  :key="cls"
                  style="margin: 2px"
                >
                  <!-- <span>{{ cls }}</span> -->
                  <span>{{ getClassName(cls) }}</span>
                </b-badge>

                <feather-icon
                  icon="EditIcon"
                  size="18"
                  @click="Edit(cardIndex)"
                  class="ml-1 cursor-pointer"
                />
              </div>
            </b-col>
          </b-row>

          <hr />

          <b-container
            v-for="(item, ind) in pcard.policy"
            :key="ind"
            class="bv-example-row"
          >
            <b-row>
              <b-col md="4" class="p-0">
                <b-form-group
                  label="Percentage From"
                  invalid-feedback="Perc from is required."
                  ref="from"
                >
                  <b-form-input
                    :id="`from${cardIndex}${ind}`"
                    type="number"
                    ref="from"
                    placeholder=""
                    v-model="item.percFrom"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" class="pr-0">
                <b-form-group
                  label="Percentage To"
                  invalid-feedback="Perc to is required."
                  ref="to"
                >
                  <b-form-input
                    type="number"
                    placeholder=""
                    ref="to"
                    v-model="item.percTo"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" class="pr-0">
                <b-form-group
                  label="Grade"
                  invalid-feedback="Grade is required."
                  ref="grade"
                >
                  <b-form-input
                    placeholder=""
                    ref="grade"
                    v-model="item.grade"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="11" class="p-0">
                <b-form-group
                  label="Remarks"
                  invalid-feedback="Remarks from is required."
                  ref="remarks"
                >
                  <b-form-input
                    :id="`remarks${cardIndex}${ind}`"
                    type="text"
                    ref="remarks"
                    placeholder="Please enter remarks"
                    v-model="item.remarks"
                  />
                </b-form-group>
              </b-col>
              <b-col md="1" class="mt-1">
                <b-button
                  v-if="ind == pcard.policy.length - 1"
                  @click="AddValue(cardIndex, ind + 1)"
                  variant="primary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="20" icon="PlusIcon" />
                </b-button>
                <b-button
                  v-else
                  @click="removeValue(item, cardIndex, ind)"
                  variant="outline-danger"
                  class="btn-icon"
                >
                  <feather-icon size="18" icon="XIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
        <!-- <b-row align-h="center">
          <b-button
            @click="removeCard(cardIndex)"
            variant="primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon size="20" icon="XIcon" />
          </b-button>
        </b-row> -->
        <b-row align-h="center">
          <b-button
            @click="addCard()"
            variant="outline-danger"
            class="btn-icon rounded-circle"
          >
            <feather-icon size="20" icon="PlusIcon" />
          </b-button>
        </b-row>
        <b-button
          class="mt-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="Add()"
          :disabled="request"
          block
        >
          <b-spinner v-if="request" small type="grow" />
          <span v-else> Save </span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  mounted() {
    window.addEventListener("keydown", this.handleEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEvent);
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
      this.LoadClasses();
    }
  },

  data() {
    return {
      rights: {},
      check: 1,
      request: false,
      sidebarTitle: "Classes for policy",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      fields: [
        { label: "Subject", key: "subject" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      rangeDate: null,
      myObj: [
        {
          policy: [
            {
              percFrom: 0,
              percTo: 0,
              grade: "",
              remars: "",
              campusID: this.$store.state.userData.cId,
            },
          ],
          cIDs: [],
        },
      ],
      currentIndex: 0,
      allClasses: [],
      classesOptions: [],
      selectedClasses: [],
      clsIDs: [],
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    getClassName(id) {
      let obj = this.allClasses.find((el) => el.id == id);
      return obj ? obj.name : "";
    },

    handleEvent(event) {
      // console.log(event);
      if (event.ctrlKey && event.keyCode === 83) {
        event.preventDefault();
        if (this.check == 1) {
          this.check = 0;
          this.Add();
        }
      }
    },

    async Edit(cIndex) {
      this.currentIndex = cIndex;
      this.selectedClasses = this.myObj[cIndex].cIDs;
      this.visibility = true;

      var elem = this.$refs["class"];
      elem.state = undefined;

      this.clsIDs = [];
      this.myObj.forEach((el, ind) => {
        if (ind != cIndex) {
          this.clsIDs = [...this.clsIDs, ...el.cIDs];
        }
      });
      // console.log(this.clsIDs);

      //   this.clsIDs = this.clsIDs.filter(
      //     (el) => !this.selectedClasses.includes(el)
      //   );
      //   console.log(this.clsIDs);

      this.classesOptions = this.allClasses.filter(
        (el) => !this.clsIDs.includes(el.id)
      );
      // console.log(this.classesOptions);
    },

    AddOpen(cIndex) {
      this.currentIndex = cIndex;
      this.visibility = true;
      this.selectedClasses = [];
      var elem = this.$refs["class"];
      elem.state = undefined;

      this.clsIDs = [];
      this.myObj.forEach((el) => {
        this.clsIDs = [...this.clsIDs, ...el.cIDs];
      });
      // console.log(this.clsIDs);

      this.classesOptions = this.allClasses.filter(
        (el) => !this.clsIDs.includes(el.id)
      );
      // console.log(this.classesOptions);
    },

    save() {
      this.visibility = false;
      this.myObj[this.currentIndex].cIDs = this.selectedClasses;
      this.selectedClasses = [];
    },

    async removeCard(cInd) {
      console.log(cInd);
      this.myObj.splice(cInd, 1);
    },

    addCard() {
      //   this.myObj.push({
      //     policy: [
      //       {
      //         id: 0,
      //         percFrom: 0,
      //         percTo: 0,
      //         grade: "",
      //         campusID: this.$store.state.userData.cId,
      //       },
      //     ],
      //     classes: [],
      //   });
      let newVal = { policy: [], cIDs: [] };
      newVal.policy = this.myObj.at(-1).policy.map((el) => {
        return {
          percFrom: el.percFrom,
          percTo: el.percTo,
          grade: el.grade,
          remarks: el.remarks,
          campusID: el.campusID,
        };
      });
      //   console.log(newVal);
      this.myObj.push(newVal);
      setTimeout(() => {
        let elem = document.getElementById(`from${this.myObj.length - 1}0`);
        elem.focus();
        elem.select();
      }, 100);
    },

    AddValue(cInd, ind) {
      this.myObj[cInd].policy.push({
        percFrom: 0,
        percTo: 0,
        grade: "",
        campusID: this.$store.state.userData.cId,
      });
      // console.log(this.myObj);
      setTimeout(() => {
        let elem = document.getElementById(`from${cInd}${ind}`);
        elem.focus();
        elem.select();
      }, 100);
    },
    async removeValue(item, cInd, ind) {
      if (item.id == 0) {
        this.myObj[cInd].policy.splice(ind, 1);
        // console.log(this.myObj);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "GradingPolicy/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.myObj[cInd].policy.splice(ind, 1);
        // console.log(this.myObj);
      }
    },
    CheckName() {
      var elem = this.$refs["subject"];
      if (this.myObj.subject == "" || this.myObj.subject > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckValues() {
      let state = true;
      this.myObj.forEach((item) => {
        item.policy.forEach((el) => {
          el.percFrom = parseInt(el.percFrom);
          el.percTo = parseInt(el.percTo);
          if (
            isNaN(el.percTo) ||
            isNaN(el.percFrom) ||
            el.percFrom >= 100 ||
            el.percFrom < 0 ||
            el.percTo <= 0 ||
            el.percTo > 100 ||
            el.grade == ""
          ) {
            state = false;
          }
        });
      });
      return state;
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "GradingPolicy/LoadClassWise?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);
      if (result.length > 0) this.myObj = result;
      else {
        this.myObj = [
          {
            policy: [
              {
                percFrom: 0,
                percTo: 0,
                grade: "",
                remarks: "",
                campusID: this.$store.state.userData.cId,
              },
            ],
            cIDs: [],
          },
        ];
      }

      this.dataLoading = false;
      setTimeout(() => {
        let first = document.getElementById("from00");
        first.focus();
        first.select();
      }, 100);
    },

    async LoadClasses() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      let data = await this.get(obj2);
      data.forEach((el) => this.allClasses.push(el.cls));
    },

    async Add() {
      //   console.log(this.myObj);
      if (this.CheckValues() == false) {
        this.check = 1;
        return this.$bvToast.toast(
          "Please enter the grading policy correctly.",
          {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          }
        );
      } else {
        //Add
        // console.log("obj:", this.myObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "GradingPolicy/SaveClassWise?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Grading Policy save successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
        this.request = false;
        this.check = 1;
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
